import React, { useState, useEffect, useRef } from 'react'
import _, { reject } from 'lodash'
import {
  Box,
  Flex,
  Icon,
  Skeleton,
  Stack,
  Text,
  ButtonGroup,
  Button,
  Input,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Switch,
} from '@chakra-ui/react'
import DrillDownTable, { TH, TR, TD } from '../../components/dashboard-table'
import ReactExport from 'react-data-export'
import {
  useGetAccounts,
  useMutateOutlier,
  useMutateTarget,
  useSIPTopDownBottomUp,
  useCheckFreeze,
  useTargetSetting,
} from '../../hooks/dashboard.hooks'
import { BiDownload } from 'react-icons/bi'
import { calculateStartEndDate } from '../../utils/dashboard.utils'
import { useDashboardContext } from '../../context/dashboard.context'
import { useAuthContext } from '../../context/auth.context'
import { dashboardConst } from '../../utils/action.constant'
import { AiFillEdit, AiOutlinePlusCircle } from 'react-icons/ai'
import moment from 'moment'
import { sectionDetailedTrack } from '../repository/repo.utils'
import { GA_CATEGORY_DASHBOARD } from './../repository/repo.utils'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

let noOfmonths = {
  1: 12,
  2: 6,
  3: 3,
  4: 1,
}

function TablePopover({
  isOpen,
  initRef,
  onClose,
  item,
  handlePopover,
  handleAttributeChange,
  attribute,
  insertUpdateTarget,
  isDisabled,
}) {
  return (
    <Popover
      isOpen={isOpen}
      initialFocusRef={initRef}
      onClose={onClose}
      placement="top-start"
      closeOnBlur={true}
    >
      <PopoverTrigger>
        {item.total_target_value ? (
          isDisabled ? (
            <Icon as={AiFillEdit} w={4} h={4} style={{ color: 'grey' }} />
          ) : (
            <Icon
              as={AiFillEdit}
              onClick={() => {
                sectionDetailedTrack({
                  category: GA_CATEGORY_DASHBOARD,
                  action: 'Widget Top Down Bottom Up View',
                  label: 'Popover Trigger',
                })

                handlePopover(item)
              }}
              w={4}
              h={4}
            />
          )
        ) : !isDisabled ? (
          <Icon
            as={AiOutlinePlusCircle}
            w={4}
            h={4}
            style={{ color: 'grey' }}
          />
        ) : (
          <Icon
            as={AiOutlinePlusCircle}
            w={4}
            h={4}
            onClick={() => {
              sectionDetailedTrack({
                category: GA_CATEGORY_DASHBOARD,
                action: 'Widget Top Down Bottom Up View',
                label: 'Popover Trigger',
              })
              handlePopover(item)
            }}
          />
        )}
      </PopoverTrigger>
      <PopoverContent p={5}>
        <PopoverArrow />
        <PopoverCloseButton />
        <Stack spacing={4}>
          <Input
            label="Target"
            id="target"
            onChange={handleAttributeChange}
            defaultValue={item.total_target_value}
          />
          <Button
            isDisabled={!attribute}
            colorScheme="teal"
            onClick={() => {
              sectionDetailedTrack({
                category: GA_CATEGORY_DASHBOARD,
                action: 'Widget Top Down Bottom Up View',
                label: 'Update',
              })
              insertUpdateTarget(!!item.total_target_value)
            }}
          >
            {item.total_target_value ? 'Update' : 'Save'}
          </Button>
        </Stack>
      </PopoverContent>
    </Popover>
  )
}

function WidgetTopDownBottomUpView({ selectedFilter }) {
  const initRef = React.useRef()
  const [download, setDownload] = useState(false)
  const [isTopDown, setIsTopDown] = useState(true)
  const [isOpenTarget, setIsOpenTarget] = useState(false)
  const [attribute, setAttribute] = useState('')
  const [selectedReportee, setSelectedReportee] = useState({})

  const {
    state: { queryFilters, bottomUpList, topDownList, sipReporteeList },
    dispatch: dashboardDispatch,
  } = useDashboardContext()

  const {
    state: { authData },
  } = useAuthContext()

  useEffect(() => {
    setIsTopDown(selectedFilter?.value === 1)
  }, [selectedFilter?.value])

  const { mutate: mutateTopDownBottomUp, isLoading } = useSIPTopDownBottomUp()
  const { mutate: checkIsFreeze } = useCheckFreeze()
  const { mutate: mutateAccounts } = useGetAccounts()

  const { mutate: mutateTarget } = useMutateTarget()
  const { mutate: mutateOutlier } = useMutateOutlier()
  const { mutate: targetSetting } = useTargetSetting()

  const loadTopDownBottomUps = reportee => {
    const {
      selectedPeriodType,
      selectedPeriod,
      selectedSipSegment,
      selectedKPIProduct,
      selectedYear,
      selectedKPIType,
      selectedKPICategory,
    } = queryFilters
    let { startDate, endDate } = calculateStartEndDate({
      selectedPeriod,
      selectedPeriodType,
      selectedYear,
    })
    const onSuccess = {
      onSuccess: data => {
        addReporteeToStack(reportee)
        if (data && Array.isArray(data)) {
          isTopDown ? addTopDownData(data) : addBottomUpData(data)
        }
      },
    }
    if (reportee.asset_flag_frontline && reportee.wrokforce_tag_id === 341) {
      mutateAccounts(
        {
          activity_type_category_id: '',
          start_from: 0,
          limit_value: 50,
        },
        onSuccess
      )
    } else {
      mutateTopDownBottomUp(
        {
          manager: reportee?.asset_id || 0,
          asset_type_id: 0,
          asset_id: 0,
          flag: 0,
          asset_tag_id: 0,
          level_id: 0,
          timeline_id: 0,
          startDate,
          endDate,
          financial_year: selectedYear?.value || '',
          cluster_tag_id: 0,
          account_id: authData.account_id || 0,
          segment: 0,
          page_start: 0,
          page_limit: 10,
          product_id: selectedKPIProduct?.value || 0,
          widget_type_id: selectedKPIType?.value || 0,
          widget_type_category_id: selectedKPICategory?.value || 0,
          period_type_id: selectedPeriodType?.value || 0,
          flag_type: isTopDown ? 1 : 2,
        },
        onSuccess
      )
    }
  }

  useEffect(() => {
    let reportee = { ...authData }
    loadTopDownBottomUps(reportee)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    queryFilters.selectedPeriod,
    queryFilters.selectedPeriodType,
    queryFilters.selectedKPIProduct,
    queryFilters.selectedKPIType,
    queryFilters.selectedKPICategory,
    queryFilters.selectedYear,
  ])

  const checkFreeze = () => {
    return new Promise(resolve => {
      const {
        selectedPeriodType,
        selectedPeriod,
        selectedYear,
        selectedKPIType,
      } = queryFilters
      let { startDate, endDate } = calculateStartEndDate({
        selectedPeriod,
        selectedPeriodType,
        selectedYear,
      })
      let payload = {
        flag: isTopDown ? 1 : 2,
        is_freeze: 0,
        level_id: 0,
        widget_type_id: selectedKPIType?.value,
        start_datetime: startDate,
        end_datetime: endDate,
        timeline_id: 1,
        period_type_id: selectedPeriodType?.value,
        start_from: 0,
        limit_value: 10,
        workforce_tag_id: selectedReportee.workforce_tag_id,
        asset_id: selectedReportee.asset_id,
        log_asset_id: authData.asset_id,
      }
      checkIsFreeze(payload, {
        onSuccess: data => {
          resolve(data)
        },
        onError: err => {
          reject(err)
        },
      })
    })
  }

  const checkTargetSetting = () => {
    return new Promise(resolve => {
      const {
        selectedPeriodType,
        selectedPeriod,
        selectedYear,
        selectedKPIType,
        selectedKPIProduct,
      } = queryFilters
      let { startDate, endDate } = calculateStartEndDate({
        selectedPeriod,
        selectedPeriodType,
        selectedYear,
      })
      let payload = {
        flag: isTopDown ? 1 : 2,
        is_freeze: 0,
        level_id: 0,
        widget_type_id: selectedKPIType?.value,
        period_start_datetime: startDate,
        period_end_datetime: endDate,
        timeline_id: 1,
        period_type_id: selectedPeriodType?.value,
        start_from: 0,
        limit_value: 10,
        product_id: selectedKPIProduct?.value,
      }
      targetSetting(payload, {
        onSuccess: data => {
          resolve(data)
        },
        onError: err => {
          reject(err)
        },
      })
    })
  }

  const insertUpdateTarget = async isUpdate => {
    try {
      await Promise.all([checkFreeze(), checkTargetSetting()])

      const {
        selectedPeriodType,
        selectedPeriod,
        selectedKPIProduct,
        selectedYear,
        selectedKPIType,
        selectedKPICategory,
      } = queryFilters
      let { startDate } = calculateStartEndDate({
        selectedPeriod,
        selectedPeriodType,
        selectedYear,
      })

      let payload = {}
      let target = parseInt(attribute)
      let months = noOfmonths[selectedPeriodType.value]
      target /= months

      if (isUpdate) {
        payload = {
          entity_target_setting_id: 1,
          target,
        }
      } else {
        payload = {
          tag_id_1: 0,
          target_value_1: 0,
          tag_id_2: 0,
          target_value_2: 0,
          tag_id_3: 0,
          target_value_3: 0,
          tag_id_4: 0,
          target_value_4: 0,
          tag_id_5: 0,
          target_value_5: 0,
          total_target_value: target,
          entity_target_inline: '{}',
          level_id: 1,
          flag_is_outlier: 1,
          flag_is_bulk: 0,
          flag_type: isTopDown ? 1 : 2,
          timeline_id: 1,
          period_type_id: selectedPeriodType?.value,
          period_start_datetime: startDate,
          period_end_datetime: moment(startDate)
            .endOf('month')
            .format('YYYY-MM-DD'),
          customer_account_type_id: 0,
          customer_account_code: 0,
          customer_account_name: '',
          product_id: selectedKPIProduct?.value,
          widget_type_id: selectedKPIType?.value,
          widget_type_category_id: selectedKPICategory?.value,
          activity_id: 1,
          asset_id: selectedReportee?.asset_id,
          log_asset_id: authData.asset_id,
        }
      }
      for (let i = 0; i < months; i++) {
        let start = moment(startDate).add(i, 'months').format('YYYY-MM-DD')
        mutateTarget(
          {
            ...payload,
            period_start_datetime: start,
            period_end_datetime: moment(start)
              .endOf('month')
              .format('YYYY-MM-DD'),
          },
          {
            onSuccess: () => {
              let reportee = { ...authData }
              setAttribute('')
              setIsOpenTarget(false)
              setSelectedReportee({})
              loadTopDownBottomUps(reportee)
            },
          }
        )
      }
    } catch {}
  }

  const updateOutlier = async value => {
    try {
      await checkFreeze()
      mutateOutlier(
        {
          entity_target_mapping_id: 1,
          flag_is_outlier: !value ? 1 : 0,
        },
        {
          onSuccess: () => {
            let list = bottomUpList
            let index = list.findIndex(item => item.id === selectedReportee.id)
            if (index > -1) {
              let item = list[index]
              item['flag_is_outlier'] = value
              list.splice(index, 1, item)
              updateOutlierDash(list)
            }
          },
        }
      )
    } catch {}
  }

  const updateOutlierDash = value => {
    dashboardDispatch({
      type: dashboardConst.UPDATE_BOTTOMUP,
      bottomUp: value,
    })
  }

  const addBottomUpData = bottomUp => {
    dashboardDispatch({
      type: dashboardConst.ADD_BOTTOMUP,
      bottomUp,
    })
  }

  const addTopDownData = topDown => {
    dashboardDispatch({
      type: dashboardConst.ADD_TOPDOWN,
      topDown,
    })
  }
  const renderHeadings = () => {
    let heads = [
      'Employee Name',
      'Employee Code',
      'Employee Email',
      'Employee Desk ID',
      'Manager Name',
      'Manager Code',
      'Manager Email',
      'Role',
      'Segment',
      'Cluster',
      'Circle',
      'Vertical',
      'KPI Code',
    ]
    if (isTopDown) {
      heads.push('Target')
    } else {
      heads.push(
        'Account/Channel Name',
        'Account/Channel Code',
        'Target',
        'Outlier'
      )
    }

    heads = heads.map(item => (
      <TH rowSpan={2} key={item}>
        {item}
      </TH>
    ))

    let tableHead = (
      <>
        <TR>
          <TH rowSpan={1}>#</TH>
          {heads}
        </TR>
      </>
    )
    return tableHead
  }

  const onClose = () => {
    setIsOpenTarget(false)
  }

  const handleAttributeChange = e => {
    setAttribute(e.target.value)
  }

  const handlePopover = item => {
    setSelectedReportee(item)
    setIsOpenTarget(true)
  }

  const addReporteeToStack = (reportee, isManager) => {
    dashboardDispatch({
      type: isManager
        ? dashboardConst.ADD_SIP_RESOURCE
        : dashboardConst.SET_SIP_RESOURCE,
      reportee: reportee,
    })
  }

  const checkIsDisabled = item => {
    let bool
    bool = item.is_freeze === 0 || item.asset_flag_frontline === 0
    return bool
  }

  const makeTableData = () => {
    let data = []
    let _arr = isTopDown ? topDownList : bottomUpList
    _arr?.forEach((item, i) => {
      let obj = {}
      obj['s.no'] = i + 1
      obj['Employee Name'] = (
        <span
          className={`cursor-pointer text-underline text-bold green-theme`}
          onClick={() => {
            sectionDetailedTrack({
              category: GA_CATEGORY_DASHBOARD,
              action: 'Widget Top Down Bottom Up View',
              label: 'Load Top Down Bottom Up',
            })
            !item.asset_flag_frontline && loadTopDownBottomUps(item)
          }}
        >
          {item.operating_asset_first_name}
        </span>
      )
      obj['Employee Code'] = item.asset_id
      obj['Employee Email'] = item.operating_asset_email_id
      obj['Manager Name'] = item.manager_operating_asset_first_name
      obj['Manager Code'] = item.manager_asset_id
      obj['Manager Email'] = item.manager_operating_asset_email_id
      obj['Role'] = item.asset_type_name
      obj['Cluster'] = item.cluster_tag_name
      obj['Circle'] = item.account_name
      obj['Vertical'] = item.workforce_tag_name
      if (isTopDown) {
        obj['Target'] = (
          <Flex>
            <span>{item.total_target_value}</span>&nbsp;&nbsp;
            <TablePopover
              isOpen={
                selectedReportee.account_id === item.account_id
                  ? isOpenTarget
                  : false
              }
              initRef={initRef}
              onClose={onClose}
              item={item}
              handlePopover={handlePopover}
              handleAttributeChange={handleAttributeChange}
              attribute={attribute}
              insertUpdateTarget={insertUpdateTarget}
              isDisabled={checkIsDisabled(item)}
            />
          </Flex>
        )
      } else {
        obj['Account Name'] = '-'
        obj['Account Code'] = '-'
        obj['Target'] = (
          <Flex>
            <span>{item.total_target_value}&nbsp;&nbsp;</span>
            <TablePopover
              isOpen={
                selectedReportee.asset_id === item.asset_id
                  ? isOpenTarget
                  : false
              }
              initRef={initRef}
              onClose={onClose}
              item={item}
              handlePopover={handlePopover}
              handleAttributeChange={handleAttributeChange}
              attribute={attribute}
              insertUpdateTarget={insertUpdateTarget}
              isDisabled={checkIsDisabled(item)}
            />
          </Flex>
        )
        obj['Outlier'] = (
          <>
            {item.total_target_value && (
              <Switch
                size="md"
                isDisabled={checkIsDisabled(item)}
                defaultChecked={!!item.flag_is_outlier}
                value={item.flag_is_outlier}
                onChange={() => updateOutlier(!!item.flag_is_outlier)}
              />
            )}
          </>
        )
      }

      data.push(obj)
    })
    return data
  }

  const renderData = () => {
    let data = makeTableData()
    return data.map((item, i) => (
      <TR key={i}>
        {Object.values(item).map((val, j) => (
          <TD key={j}>{val}</TD>
        ))}
      </TR>
    ))
  }

  const handleDownload = () => {
    setDownload(true)
  }

  const exportHeadings = () => {
    let heads = [
      'Employee Name',
      'Employee Code',
      'Employee Email',
      'Employee Desk ID',
      'Manager Name',
      'Manager Code',
      'Manager Email',
      'Role',
      'Segment',
      'Cluster',
      'Circle',
      'Vertical',
      'Target',
    ]
    return heads
  }

  const renderExportData = () => {
    let dataSet = makeTableData()

    const fileName = 'SIP_Download'
    return (
      <ExcelFile filename={fileName} hideElement={true}>
        {[
          {
            sheet: isTopDown ? 'Top Down' : 'Bottom Up',
            data: dataSet,
            heads: exportHeadings(),
          },
        ].map(item => (
          <ExcelSheet data={item.data} name={item.sheet}>
            {item.heads?.map(headerItem => (
              <ExcelColumn label={headerItem} value={headerItem} />
            ))}
          </ExcelSheet>
        ))}
      </ExcelFile>
    )
  }

  const widgetMainView = isLoading ? (
    <Stack>
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
    </Stack>
  ) : (
    <Box>
      <Flex mb={3} p={3} alignItems="center">
        <Icon
          as={BiDownload}
          w={6}
          h={6}
          onClick={() => {
            sectionDetailedTrack({
              category: GA_CATEGORY_DASHBOARD,
              action: 'Widget Top Down Bottom Up View',
              label: 'Download',
            })
            handleDownload()
          }}
          className={`cursor-pointer`}
        />
        &nbsp;&nbsp;
        {download && renderExportData()}
        {!!sipReporteeList &&
          sipReporteeList.map((item, i) => (
            <>
              {i !== 0 ? '/' : null}
              <Text
                fontWeight="bold"
                onClick={() => {
                  sectionDetailedTrack({
                    category: GA_CATEGORY_DASHBOARD,
                    action: 'Widget Top Down Bottom Up View',
                    label: 'Load Top Down Bottom Up',
                  })
                  loadTopDownBottomUps(item)
                }}
                color={localStorage.getItem('color')}
                cursor="pointer"
                textDecoration="underline"
              >
                {' '}
                {item.operating_asset_first_name}{' '}
              </Text>
            </>
          ))}
      </Flex>
      <VStack spacing={8}>
        <DrillDownTable
          theadings={renderHeadings()}
          tdata={renderData()}
          hideAlpine={true}
        />
      </VStack>
    </Box>
  )

  return (
    <Box bg="white" rounded="md" p="8px">
      {widgetMainView}
    </Box>
  )
}

export default WidgetTopDownBottomUpView
